export class ColorPalette {
    static blue = 'rgba(54, 162, 235, 1)'; 
    static blue50 = 'rgba(54, 162, 235, .5)'; 

    static green = 'rgba(75, 192, 192, 1)';
    static green50 = 'rgba(75, 192, 192, .5)';

    static orange = 'rgba(255, 159, 64, 1)';
    static orange50 = 'rgba(255, 159, 64, .5)';

    static purple = 'rgba(153, 102, 255, 1)';
    static purple50 = 'rgba(153, 102, 255, .5)';

    static red = 'rgba(255, 99, 132, 1)';
    static red50 = 'rgba(255, 99, 132, .5)';

    static yellow = 'rgba(255, 205, 86, 1)';
    static yellow50 = 'rgba(255, 205, 86, .5)';

    static gray = 'rgba(201, 203, 207, 1)';
    static gray50 = 'rgba(201, 203, 207, .5)';
    static gray25 = 'rgba(201, 203, 207, .25)';

    static white = 'rgba(255, 255, 255, 1)';
    static white50 = 'rgba(255, 255, 255, .5)';

    static black = 'rgba(0, 0, 0, 1)';
    static black50 ='rgba(0, 0, 0, .5)';
}