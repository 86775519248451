// Custom JS imports -- specific to the Home page
import Chart from 'chart.js/auto';
import 'chartjs-adapter-moment';

export function renderTimeSeriesChart(chartId = '', seriesData = [], seriesColors = [], 
    fill = false, aspectRatio = undefined, showAxes = false, showLegend = false, showTooltips = false,
    showAnimations = false) {
    var ctx = document.getElementById(chartId).getContext('2d');
    var timeSeriesChart = new Chart(ctx, {
        type: 'line',
        data: {
            datasets: seriesData
        },
        options: {
            parsing: false,
            animation: showAnimations,
            responsive: true,
            aspectRatio: aspectRatio,
            maintainAspectRatio: true,
            datasets: {
                line: {
                    borderColor: seriesColors,
                    backgroundColor: seriesColors,
                    fill: fill
                }
            },
            elements: {
                line: {
                    tension: 0.1
                },
                point: {
                    radius: 0
                }
            },
            plugins: {
                legend: {
                    display: showLegend,
                    position: 'bottom'
                },
                tooltip: showTooltips
            },
            scales: {
                x: {
                    type: 'time',
                    display: showAxes,
                    ticks: {
                        color: 'white'
                    }
                },
                y: {
                    display: showAxes,
                    beginAtZero: false,
                    ticks: {
                        color: 'white'
                    }
                }
            }
        }
    });
}
