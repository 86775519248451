// Custom JS imports -- specific to the Home page
import Chart from 'chart.js/auto';
import 'chartjs-adapter-moment';

export function renderHorizontalBarChart(chartId = '', data = [], axisLabels = [], dataColors = [],
    aspectRatio = undefined, showAnimations = false) {
    var ctx = document.getElementById(chartId).getContext('2d');
    var horizontalBarChart = new Chart(ctx, {
        type: 'bar',
        data: {
            labels: axisLabels,
            datasets: [{
                data: data,
                backgroundColor: dataColors
            }]
        },
        options: {
            indexAxis: 'y',
            animation: showAnimations,
            responsive: true,
            aspectRatio: aspectRatio,
            maintainAspectRatio: true,
            plugins: {
                legend: {
                    display: false
                }
            },
            scales: {
                x: {
                    display: true,
                    ticks: {
                        color: 'white'
                    }
                },
                y: {
                    display: true,
                    ticks: {
                        color: 'white'
                    }
                }
            }
        }
    });
}
