// Custom JS imports -- specific to the Home page
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels'; 

//Add custom plugin to show center label
Chart.register({
    id: 'doughnutCenter',
    beforeDraw: function (chart) {
        if (chart.config.options.elements?.center) {  
            //Get ctx from string
            var ctx = chart.ctx;
            
            //Get options from the center object in options
            var centerConfig = chart.config.options.elements.center;
            var fontStyle = centerConfig.fontStyle || 'Arial';
            var txt = centerConfig.text;
            var color = centerConfig.color || '#000';
            var sidePadding = centerConfig.sidePadding || 20;
            var sidePaddingCalculated = (sidePadding/100) * (chart.innerRadius * 2)
            //Start with a base font of 30px
            ctx.font = "30px " + fontStyle;
            
            //Get the width of the string and also the width of the element minus 10 to give it 5px side padding
            var stringWidth = ctx.measureText(txt).width;
            var elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;

            // Find out how much the font can grow in width.
            var widthRatio = elementWidth / stringWidth;
            var newFontSize = Math.floor(30 * widthRatio);
            var elementHeight = (chart.innerRadius * 2);

            // Pick a new font size so it will not be larger than the height of label.
            var fontSizeToUse = Math.min(newFontSize, elementHeight);

            //Set font settings to draw it correctly.
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            var centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
            var centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
            ctx.font = fontSizeToUse+"px " + fontStyle;
            ctx.fillStyle = color;
            
            //Draw text in center
            ctx.fillText(txt, centerX, centerY);
        }
    }
});

export function renderDoughnutChart(chartId = '', seriesData = [], seriesLabels = [], centerText = '',
    seriesColors = [], labelTextColor = 'white', centerTextColor = 'white', cutoutPercent = '75%',
    showAnimations = false) {
    var ctx = document.getElementById(chartId).getContext('2d');
    var doughnutChart = new Chart(ctx, {
        type: 'doughnut',
        data: {
            labels: seriesLabels,
            datasets: [{
                data: seriesData,
                borderWidth: 0,
                backgroundColor: seriesColors,
                cutout: cutoutPercent
            }]
        },
        plugins: [ChartDataLabels],
        options: {  
            animation: showAnimations,
            responsive: true,
            maintainAspectRatio: true,
            plugins: {
                legend: {
                    display: false
                },
                datalabels: {
                    color: labelTextColor,
                    font: {
                        weight: 'bold'
                    },
                    formatter: function(value, context) {
                        return context.chart.data.labels[context.dataIndex];
                    }
                }
            },
            elements: {
                center: {
                    text: centerText,
                    color: centerTextColor, // Default is #000000
                    fontStyle: 'Arial', // Default is Arial
                    sidePadding: 20 // Default is 20 (as a percentage)
                }
            }
        }
    });
}
